module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-tinacms/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/home/runner/work/iceage-website/iceage-website/node_modules/gatsby-tinacms-git","id":"4628ab64-7b6e-5b53-96be-da74c8f9ab37","name":"gatsby-tinacms-git","version":"0.4.8","pluginOptions":{"plugins":[],"gitRemote":"git@github.com:Integral-Stack/iceage-website.git","defaultCommitMessage":"Edited with TinaCMS","defaultCommitName":"Cloud Editor","defaultCommitEmail":"editor@iceagepipelines.com"},"nodeAPIs":["onCreateDevServer"],"browserAPIs":["onClientEntry"],"ssrAPIs":[]},{"resolve":"/home/runner/work/iceage-website/iceage-website/node_modules/gatsby-tinacms-json","id":"acc053f9-4f17-5fb2-9948-4bd93063a73c","name":"gatsby-tinacms-json","version":"0.8.19","pluginOptions":{"plugins":[]},"nodeAPIs":["setFieldsOnGraphQLNodeType"],"browserAPIs":[],"ssrAPIs":[]}],"sidebar":{"hidden":true,"position":"displace"}},
    },{
      plugin: require('../node_modules/gatsby-tinacms-git/gatsby-browser.js'),
      options: {"plugins":[],"gitRemote":"git@github.com:Integral-Stack/iceage-website.git","defaultCommitMessage":"Edited with TinaCMS","defaultCommitName":"Cloud Editor","defaultCommitEmail":"editor@iceagepipelines.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#ffffff","theme_color":"#2375D1","display":"minimal-ui","icon":"src/images/inline/logo-only.svg"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
